const DataSector = [
    {
        name: "To-do List",
        icon: "✅",
        img: "todolistapp",
        alt: "This is a to-do list webapp.",
        description: "I made a to-list using react. This is a basic app but it helped me to improve and use local storage so the entered data is saved everytime we leave the webapp. Very useful for shopping 🛒.",
        link: "https://dafuziah.github.io/To-do-list/",
        date: "01-07-2022",
    },
    {
        name: "Responsive Gallery",
        icon: "📸",
        img: "responsive_gallery",
        alt: "Responsive gallery about tokyo city.",
        description: "This is a responsive gallery using css grid displaying pictures about Tokyo City.",
        link: "https://dafuziah.github.io/responsive-gallery/",
        date: "01-11-2021",
    },
    {
        name: "Weather app",
        icon: "🌦️",
        img: "weatherapp",
        alt: "This is a weather webapp.",
        description: "",
        link: "https://next-weather-app-blue.vercel.app/",
        date: "01-03-2023",
    },
    {
        name: "3D NES Controller",
        icon: "🎮",
        img: "nes_controller",
        alt: "3D Nes Controller made with threejs.",
        description: "",
        link: "https://nes-controller.netlify.app/",
        date: "01-01-2023",
    },
    {
        name: "UI Templates",
        icon: "🎨",
        img: "uitemplates",
        alt: "This is a UI prototype about a german furniture website.",
        description: "Collection about UI study for a german furniture website called Möbl. The template is free to use.",
        link: "https://dribbble.com/shots/22114750-Colorful-Nav-Footer-Concept",
        date: "01-08-2023",
    },
];

export default DataSector