import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import Socials from '../Extras/Socials';
import './Nav.scss';


const SidebarNav = props => {
  let sidebarMenu = useRef(null);
  let sidebarMenuOverlay = useRef(null);
  let menuLayer = useRef(null);
  let menuWrapper = useRef(null);
  const menuTimeline = useRef();
  const linkTimeline = useRef();
  const socialTimeline = useRef();

  const { menuState, setMenuState } = props;

  useEffect(() => {
    menuTimeline.current = gsap.timeline({ paused: true });
    menuTimeline.current.to([menuWrapper], { 
      duration: 0.01,
      x: '0%',
      ease: 'power3.inOut',
      // display: 'inherit',
      stagger: {
        amount: 0.5
      }
      })
    menuTimeline.current.to(
      [sidebarMenuOverlay, menuLayer, sidebarMenu],
      {
        duration: 0.75,
        x: '0%',
        ease: 'power3.inOut',
        // display: 'inherit',
        stagger: {
          amount: 0.5
        }
      }
    )
    // .to('.burger-trigger .bars', { x: '-120%', duration: .1, stagger: .2 })
    // .to('.arrow', { x: 0, duration: .2 });
    // menuTimeline.current.to(['.burger-trigger div'], { opacity: 1, x: '-100%', duration: .15, stagger: .2 })
    // menuTimeline.current.to(['.menu-link'], { opacity: 1, y: 0, duration: .1, stagger: .2 })
    // menuTimeline.current.to(['.socials-item'], { opacity: 1, y: 0, duration: .1, stagger: .2 })

  }, []);

  useEffect(() => {
    linkTimeline.current = gsap.timeline({ paused: true });
    linkTimeline.current.to(['.menu-link'], { opacity: 1, y: 0, delay: .75, duration: .1, stagger: .2 })
  }, [])

  useEffect(() => {
    socialTimeline.current = gsap.timeline({ paused: true });
    socialTimeline.current.to(['.socials-item'], { opacity: 1, y: 0, delay: 1.5, duration: .1, stagger: .1 })
  }, [])

  useEffect(() => {
    menuState ? menuTimeline.current.timeScale(1).play() : menuTimeline.current.timeScale(2).reverse();

    menuState ? linkTimeline.current.timeScale(1).play() : linkTimeline.current.timeScale(2).reverse();

    menuState ? socialTimeline.current.timeScale(1).play() : socialTimeline.current.timeScale(2).reverse();

  }, [menuState]);

  return (
    <>
      <div
        className="NavigationOverlay"
        ref={el => (sidebarMenuOverlay = el)}
        onClick={() => setMenuState(false)}
      ></div>
      <div className="menu-wrapper" ref={el => (menuWrapper = el)}>
        <div className="menu-layer" ref={el => (menuLayer = el)}></div>
        <nav className="sidebarNavigation" ref={el => (sidebarMenu = el)}>
          <div className="link-wrapper">
          <div className="menu-link is-moused"><Link className="menu-link-link" to="/" onClick={() => setMenuState(false)}>Home</Link></div>
          <div className="menu-link is-moused"><Link className="menu-link-link" to="/sector" onClick={() => setMenuState(false)}>Sector G7 🚧</Link></div>
          <div className="menu-link is-moused"><Link className="menu-link-link" to="/about" onClick={() => setMenuState(false)}>About</Link></div>
          <div className="menu-link is-moused"><Link className="menu-link-link" to="/contact" onClick={() => setMenuState(false)}>Contact</Link></div>
          </div>
        <div className="socials-wrapper">
          <Socials></Socials>
        </div>
        </nav>
      </div>
    </>
  );
};
export default SidebarNav;
