import './HomeHero.scss';
import {gsap} from 'gsap';
import {useEffect} from 'react';

function Header() {

  let tlHeader = gsap.timeline({ defaults: { ease: "power3InOut" }});

  useEffect(() => {
    tlHeader.to('.is-appear', {opacity: 1, duration: .3, x: 0, y: 0, delay: .2, stagger: .2 })
  })

  return (
    <div className='header-hero'>
      <h1 className='header-title'> <span className='username is-appear'>Guillaume Sauteur</span> <span className='text-red is-appear'> ➔ 2023 Portfolio</span></h1>

      <div className="header-hero-status">
        <div className="status is-appear">INTERACTIVE <br />MEDIA <br />DESIGNER</div>
        <div className="status is-appear">➔ Based in Fribourg, <br />Switzerland</div>
      </div>
    </div>
  );
}

export default Header;

