import React from 'react'
import './Section.scss'

function Section() {
  return (
    <div className="wrapper other-reveal">
        <div className="section-divider">
          <div>
            <span className="crochet text-red">[</span>SELECTED PROJECTS<span className="crochet text-red">]</span>
          </div>
          {/* <div>
            <span className="crochet text-red">[</span>SELECTED PROJECTS<span className="crochet text-red">]</span>
          </div> */}
          </div>
    </div>
  )
}

export default Section