import {useEffect} from 'react';
import './Background.scss';
import {gsap} from 'gsap';


let tl = gsap.timeline({ defaults: { ease: "power4Out" }});
let tl2 = gsap.timeline({ defaults: { ease: "power4Out" }});

function Background() {


  useEffect(() => {
    tl.to('.vertical-lines', { height: '100%', duration: 1.7, stagger: .3 })
    tl2.to('.horizontal-lines', { width: '100%', duration: 1.7, stagger: { each: .3 }, delay: .3 })
  })


    return (
      <div className="background">
        <div className="vertical">
          <div className="vertical-lines"></div>
          <div className="vertical-lines"></div>
          <div className="vertical-lines"></div>
          <div className="vertical-lines"></div>
          <div className="vertical-lines"></div>
          <div className="vertical-lines"></div>
        </div>
        <div className="horizontal">
          <div className="horizontal-lines"></div>
          <div className="horizontal-lines"></div>
          <div className="horizontal-lines"></div>
          <div className="horizontal-lines"></div>
          <div className="horizontal-lines"></div>
          <div className="horizontal-lines"></div>
        </div>
      </div>
    );

  }
  
  export default Background
  export {tl, tl2};