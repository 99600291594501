import SectorHero from "./Header/SectorHero";
import React, {useEffect} from "react";
import gsap from "gsap";
import SectorContent from "./Sector/SectorContent";
import Credits from "./Extras/Credits";
import './Sector/Sector.scss';
import { Helmet } from "react-helmet";

function Sector() {

  var sectorTitle = "Guillaume Sauteur - SectorG7";

  window.onfocus = function () { document.title = sectorTitle; }

  let tlHeader = gsap.timeline({ defaults: { ease: "power3InOut" }});

  useEffect(() => {
    tlHeader.to('.is-appear', {opacity: 1, duration: .6, y: 0, delay: 1, stagger: .2 })
  })

    return (
      <div className="Sector">
      <Helmet>
        <title>{sectorTitle}</title>
        <meta name='description' content='Page that shows experiments project made by Guillaume Sauteur.' />
      </Helmet>
        <SectorHero></SectorHero>
        <SectorContent />
        <Credits />
      </div>
    );
  }
  
  export default Sector