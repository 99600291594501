import React from 'react'

function Idendity({ year, client, role, credits }) {
  return (
    <div className="identity">
        <h2 className="identity-title">
            Project Info
        </h2>
        <div className="identity-card">
            <div className="identity-card-item"><h3> Client </h3><h4> <span className="text-red">➔</span> { client }</h4></div>
            <div className="identity-card-item"><h3> Credits </h3><h4> <span className="text-red">➔</span> { credits }</h4></div>
            <div className="identity-card-item"><h3> Tasks </h3><h4><span className="text-red">➔</span> { role }</h4></div>
            <div className="identity-card-item"><h3> Year </h3><h4><span className="text-red">➔</span> { year }</h4></div>
        </div>
    </div>
  )
}

export default Idendity