import {DataProjects} from './Datas-project';
import ProjectItem from './ProjectItem';

import './projects.scss';
import './project-navigation.scss';

function Projects() {


    return (
      <div className="Project-layout">
        {DataProjects.map((DataProjects, idx) => {
            return (
              <ProjectItem key={idx} id={idx} name={DataProjects.name} image={DataProjects.image} category={DataProjects.category} status={DataProjects.status} nameResp={DataProjects.nameResponsive}/>
            );
        })}
      </div>
    );
  }
  
  export default Projects;