import React from 'react';
import { useEffect } from 'react';
import DataSector from './DataSector';
import SectorItem from './SectorItem';

const SectorContent = () => {

  useEffect(() => {
    document.querySelector(".sector-container").onmousemove = e => {
      for(const card of document.querySelectorAll(".sector-item")) {
        const radial = card.getBoundingClientRect(),
              x = e.clientX - radial.left,
              y = e.clientY - radial.top;
    
        card.style.setProperty("--mouse-x", `${x}px`);
        card.style.setProperty("--mouse-y", `${y}px`);
        // console.log("--mouse-x")
      };
    }
  })
  
  return (
    <div className="sector-layout">
        <h2 className="header-second-title is-appear">The <b className="text-red">Sector</b> is a page for projects experimentations, mostly personal discoveries. You will find here some little personal projects made for improving without a real context.</h2>
        <div className="sector-container">
          {DataSector.map((DataSector, idx) => {
            return (
              <SectorItem key={idx} id={idx} name={DataSector.name} link={DataSector.link} img={DataSector.img} alt={DataSector.alt} icon={DataSector.icon} description={DataSector.description} date={DataSector.date} />
              )
            })}
          </div>
    </div>
  )
}

export default SectorContent