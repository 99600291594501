import React, { useEffect, useState } from 'react'
import { DataProjects } from './Datas-project';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Idendity from './Idendity';
// import NavigateTool from './NavigateTool';
import gsap from 'gsap';
import { Helmet } from 'react-helmet';

const ProjectsDisplay = () => {


    const [loading, setLoading] = useState(false);

    useEffect(() => {
        tlProject.to(['.project-overlay', '.project'], { y: 0, x: 0, opacity: 1, duration: .5, stagger: { amount: 0.25 } });
        tlProject.to('.is-appearing', { y: 0, x: 0, opacity: 1, duration: .5, stagger: { amount: 0.25 } });
    });

    useEffect(() => {
        tlProject.to('.nav-anime', {y: 0, opacity: 1, duration: .75})
    })

    const loadData = async () => {

        // tlProject.to(['.project-overlay', '.project' ], { y: '100%', x:0, opacity: 1, duration: .5, stagger: { amount: 0.25 } });
        // tlProject.to('.is-appearing', { y: '100%', x:0, opacity: 1, duration: .5, stagger: { amount: 0.25 } });
        // Wait for two second
        // tlProject.fromTo('.loading-background', { y: '0', x: 0, opacity: 1, duration: .5, stagger: { amount: 0.25 } }, { y: '100%' });
        await new Promise((r) => setTimeout(r, 1500));

        // Toggle loading state
        setLoading((loading) => !loading);
    };

    window.onfocus = function () { document.title = project.name; }

    const navigate = useNavigate();
    const { id } = useParams();
    const project = DataProjects[id];
    // console.log(project.name, id);
    // const project = DataProjects.find((p) => p._id === id);
    // const project = DataProjects.find(project => project.id === (id));

    // var currentItem = document.querySelector(project.name);

    // function backToProject(){
    //     currentItem.scrollIntoView({behavior: 'smooth'});
    //     console.log(project.name)
    // };


    let tlProject = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

    useEffect(() => {
        document.querySelectorAll('.button-navigation').forEach(link => {
            link.addEventListener('click', function () {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            })
        });
    }, []);

    const nextProject = async () => {

        setLoading(true);
        tlProject.fromTo('.loading-background', { y: '0', x: 0, opacity: 1, duration: .5, stagger: { amount: 0.25 } }, { y: '100%' });
        await new Promise((r) => setTimeout(r, 1500));
        setLoading((loading) => !loading);

        navigate("/Project/" + `${project.next}`);

    }

    const previousProject = async () => {

        setLoading(true);
        tlProject.fromTo('.loading-background', { y: '0', x: 0, opacity: 1, duration: .5, stagger: { amount: 0.25 } }, { y: '100%' });
        await new Promise((r) => setTimeout(r, 1500));
        setLoading((loading) => !loading);

        navigate("/Project/" + `${project.previous}`);

    }

    if (loading) {
        return <div className="loading-background">
            {/* <div className="loading-logo">

            </div> */}
            <div className="loading-logo-rounded">
                
            </div>
        </div>
    } else {
        return (
            <>
                <Helmet>
                    <title>{project.name}</title>
                    <meta name='description' content='' />
                </Helmet>
                <div className="project-overlay second">

                </div>
                <div className="project-overlay">

                </div>
                <div className="project">
                    <div className="project-padding">

                    
                    <div className="back-link arrow-invert back-project"><Link className="menu-link-link is-moused" to="/">➔</Link></div>
                    <div className={`project-display-content ${project.restriction}`}>
                        <div className="item">
                            <div className="item-inner is-appearing">
                                <div className="project-display-header is-appearing">
                                    <h1 className="project-display-title">
                                        {project.name}
                                    </h1>
                                    <h2 className="project-display-category">
                                        <span className="crochet text-red">[</span> {project.category} <span className="crochet text-red">]</span>
                                    </h2>
                                </div>
                                <p className="project-display-description">
                                    {project.description}
                                </p>
                                <div className="arrow-down text-red">
                                    ➔
                                </div>
                                <a className="external-link is-moused" href={`${project.website}`} key={project.id} target="_blank" rel="noreferrer">
                                    <span className='text-red'>➔</span>
                                    Visit the website
                                </a>
                            </div>
                        </div>
                        <div className={`img-wrapper is-appearing`}>
                            <Idendity year={project.year} client={project.client} role={project.role} credits={project.credits} />

                            <img className="project-display-hero" src={`../img/${project.image}.webp`} alt="Project." />

                            <img className="project-display-hero" src={`../img/${project.image}-first.webp`} alt="Project." />

                            <img className="project-display-hero" src={`../img/${project.image}-second.webp`} alt="Project." />

                            <img className="project-display-hero" src={`../img/${project.image}-third.webp`} alt="Project." />

                            <img className="project-display-hero" src={`../img/${project.image}-fourth.webp`} alt="Project." />

                            <video controls width="100%" height="100%" src={project.video}></video>
                        </div>
                    </div>

                    <div className="rights is-appearing">
                        © {project.year} – All Rights reserved
                    </div>
                </div>
                    </div>
                    <div className={`project-navigation navigation-${project.id} is-appearing nav-anime`}>
                        <div className="project-navigation-inner">
                            <div className={`navigation-wrapper previous-project previous-no-display-${project.id}`}>
                                <div className="navigation-content is-moused" onClick={previousProject}>
                                <span className="arrow-invert invert">➔</span> PREVIOUS PROJECT
                                </div>
                            </div>
                            <div className={`navigation-wrapper next-project next-no-display-${project.id}`}>
                                <div className="navigation-content is-moused" onClick={nextProject}>
                                    NEXT PROJECT <span className="arrow-invert">➔</span>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        )
    }

}

export default ProjectsDisplay