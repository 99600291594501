import './HomeHero.scss';
import {gsap} from 'gsap';
import {useEffect} from 'react';

function AboutHero() {

  let tlHeader = gsap.timeline({ defaults: { ease: "power3InOut" }});

  useEffect(() => {
    tlHeader.to('.is-appear', {opacity: 1, duration: .6, y: 0, delay: 1, stagger: .2 })
  })

  return (
    <div className="container-layout">
      <div className="container-inner">
        <div className='header-hero header-about'>
          <h1 className='header-title'> <span className='text-red is-appear'>ABOUT ME</span> </h1>
          <div className='header-hero-portrait is-appear'>
            <img src="./img/portrait.webp" alt="Portrait of me." />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHero;

