const DataSocials = [
    {
        name: 'Behance',
        logo: 'fa-brands fa-behance',
        adress: 'https://www.behance.net/iGUI'
    },
    {
        name: 'Instagram',
        logo: 'fa-brands fa-instagram',
        adress: 'https://www.instagram.com/guigui.motion/'
    },
    {
        name: 'Codepen',
        logo: 'fa-brands fa-codepen',
        adress: 'https://codepen.io/DaFuziaH'
    },
    {
        name: 'Github',
        logo: 'fa-brands fa-github',
        adress: 'https://github.com/DAFUZIAH'
    },
    {
        name: 'Linked In',
        logo: 'fa-brands fa-linkedin-in',
        adress: 'https://www.linkedin.com/in/guillaume-sauteur-7ab608226/'
    },
    {
        name: 'Stack Overflow',
        logo: 'fa-brands fa-stack-overflow',
        adress: 'https://stackoverflow.com/users/21716297/gui'
    },
    // {
    //     name: 'Twitter',
    //     logo: 'fa-brands fa-twitter',
    //     adress: 'https://twitter.com/DaFuziaH'
    // },
]

export default DataSocials