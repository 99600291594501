import './variables.scss';
import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Nav from './Nav/Nav';
import Header from './Header/Header';
import Contact from './Contact';
import Sector from './Sector';
import About from './About';
import Home from './Home';
import PageNotFound from './PageNotFound';
import Cursor from './Extras/cursor';
import Background from './Extras/Background';
import ProjectsDisplay from './Projects/ProjectsDisplay';
import gsap from 'gsap';

const App = () => {

  window.onblur = function () { document.title = 'Take a look!'; };

  let [menuState, setMenuState] = useState(false);

  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  // }, []);

  // 👇️ scroll to top on button link click
  useEffect(() => {
    document.querySelectorAll('.menu-link').forEach(link => {
      link.addEventListener('click', function(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      })
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll('.is-moused').forEach(link => {
      link.addEventListener('mouseover', function(){
        document.querySelector('.cursor-content').classList.add('is-squared');
      })
      link.addEventListener('mouseout', function(){
        document.querySelector('.cursor-content').classList.remove('is-squared');
      })
    });
  }, []);

  useEffect(() => {
    let tl2Header = gsap.timeline({ ease: 'power3.inOut' });
    tl2Header.fromTo('.header', { y: "-100%" }, { y: 0, duration: .75 });
  }, []);

  useEffect(() => {
    let tlCredits = gsap.timeline({ ease: 'power3.inOut' });
    tlCredits.fromTo('.Credits', { y: "-100%" }, { y: 0, duration: .75 });
  }, []);

  return (
    <div className="App">
      
    <Background></Background>

    <Router>

    <Cursor></Cursor>

    <Header menuState={menuState} setMenuState={setMenuState}></Header>
    <Nav menuState={menuState} setMenuState={setMenuState}></Nav>


      <Routes>
        <Route index element={<Home />} />
        <Route path="Project/:id" element={<ProjectsDisplay />} />
        <Route path="Sector" element={<Sector />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>

    </Router>

    </div>
  );
}

export default App;
