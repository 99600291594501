import React, { useRef, useEffect } from 'react';
import './Cursor.scss';

const Cursor = () => {

  const mainCursor = useRef(null);
  const secondaryCursor = useRef(null);

  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1
  });
    
  useEffect (() => {
    document.addEventListener('mousemove', (event)=>{
      const {clientX, clientY} = event;
      const mouseX = clientX;
      const mouseY = clientY;

      positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
      positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;

      mainCursor.current.style.top = ` ${mouseY - mainCursor.current.clientHeight / 2}px `;
      mainCursor.current.style.left = ` ${mouseX - mainCursor.current.clientWidth / 2}px`;
      // cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
      // console.log(clientX, clientY);
    });

    document.addEventListener('mousedown', function(){
      mainCursor.current.classList.add('little')
    });

    document.addEventListener('mouseup', function(){
      mainCursor.current.classList.remove('little')
    });
    
    document.querySelector('.NavigationOverlay').addEventListener('mouseover', function(){
      mainCursor.current.classList.add('is-crossed');
    });

    document.querySelector('.NavigationOverlay').addEventListener('mouseout', function(){
      mainCursor.current.classList.remove('is-crossed');
    });

    document.querySelector('.NavigationOverlay').addEventListener('click', function(){
      mainCursor.current.classList.remove('is-crossed');
    });

    // document.querySelector('.menu-link').addEventListener('mouseover', function(){
    //   mainCursor.current.classList.add('is-squared');
    // })

    // document.querySelector('.menu-link').addEventListener('mouseout', function(){
    //   mainCursor.current.classList.remove('is-squared');
    // })

    // document.querySelector('.NavigationOverlay').addEventListener('mouseout', function(){
    //   tlQuit.to('.cursor', { background: 'red', duration: .5 })
    // })

    return () => {};
  }, []);

  useEffect(() => {
  const followMouse = () => {
    positionRef.current.key = requestAnimationFrame(followMouse);

    const {
      mouseX,
      mouseY,
      destinationX,
      destinationY,
      distanceX,
      distanceY,
    } = positionRef.current;
    if (!destinationX || !destinationY) {
      positionRef.current.destinationX = mouseX;
      positionRef.current.destinationY = mouseY;
    } else {
      positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
      positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
      if (
        Math.abs(positionRef.current.distanceX) +
          Math.abs(positionRef.current.distanceY) <
        0.1
      ) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.destinationX += distanceX;
        positionRef.current.destinationY += distanceY;
      }
    }
    secondaryCursor.current.style.top = ` ${destinationY}px`;
    secondaryCursor.current.style.left = `${destinationX}px`;
  };
  followMouse();
  }, []);
    return(
        <>
        <div className="cursor" ref={ mainCursor }> <div className="cursor-content"><h2 className="cursor-title">SHOW MORE</h2></div> </div>
        <div className="secondary-cursor" ref={secondaryCursor}></div>
        </>
    )
}

export default Cursor



// var containerRight = document.querySelector('.right');

// containerRight.addEventListener('mouseover', function(){
//   circle.classList.add('is-visible')
// })

// containerRight.addEventListener('mouseout', function(){
//   circle.classList.remove('is-visible')
// })

// card.addEventListener('mouseover', function(){
//   circle.classList.add('is-smaller')
// })

// card.addEventListener('mouseout', function(){
//   circle.classList.remove('is-smaller')
// })