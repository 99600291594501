import './HomeHero.scss';

function SectorHero() {

  return (
    <div className="container-layout">
      <div className="container-inner">
        <div className='header-hero sector'>
          <h1 className='header-title'> <span className='text-red is-appear'>SECTOR G7</span> </h1>
        </div>
      </div>
    </div>
  );
}

export default SectorHero;

