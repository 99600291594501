import AboutHero from "./Header/AboutHero";
import { useEffect } from 'react';
import './About/about.scss';
// import Skills from "./About/Skills";
import Services from "./About/Services";
import Credits from './Extras/Credits';
import { Helmet } from "react-helmet";


function About() {

  var aboutTitle = "Guillaume Sauteur - About";

    window.onfocus = function () { document.title = aboutTitle; }

      useEffect(() => {
        var appear = document.querySelectorAll('.about-section')
        let i;
        // let tlCard = gsap.timeline({ defaults: { ease: "power4Out" }});

        function reveal(){
            
            for( i = 0; i < appear.length; i++ ){
              
              var windowHeight = window.innerHeight;
              var topPoint = appear[i].getBoundingClientRect().top;
              var revealpoint = 150;
              
              if(topPoint < windowHeight - revealpoint){
              appear[i].classList.add('active');
              // tlCard.to('.project-card', { opacity: 1, y: 0, duration: .6, stagger: .4 })
              }
              else{
                appear[i].classList.remove('active');
                // tlCard.to('.project-card', { opacity: 0, y: 50, duration: .6 });
              }
            }
          }
            
    window.addEventListener('scroll', reveal);
    }, []);

    return (
      <div className="About">
      <Helmet>
        <title>Guillaume Sauteur - About</title>
        <meta name='description' content='Page that describe the profile of Guillaume Sauteur.' />
      </Helmet>
      <AboutHero></AboutHero>

        <div className="about-section">
          <h2 className="description-title">
            Who I am
          </h2>
          <p className="description-content">
            My name's Guillaume Sauteur, I am working through differents creative projects and I enjoy sharing my work.
            <br />
            <br />
            I am an <b className="text-red">Interactive Media Designer </b> experiencing in illustration, motion design and frontend development.
          </p>
        </div>
        {/* <div className="about-section">
          <h2 className="description-title">
            My skills
          </h2>
        <Skills></Skills>
        </div> */}
        <div className="about-section services-section">
          <h2 className="description-title">
            My services
          </h2>
          <p className="description-content">
            I promote the diversity of my profession and provide a range of services that are complementary.
            <br />
            <br />
            I work on different projects such as websites, branding, illustration and motion design because it allows me to exploit different media that are all united by creativity. In addition, it helps me to perform better and to achieve my professional goals.
          </p>
          <Services></Services>
        </div>
      <Credits></Credits>
      </div>
    );
  }
  
  export default About