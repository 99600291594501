import ContactHero from './Header/ContactHero';
import Socials from './Extras/Socials';
import Credits from './Extras/Credits';
import { Helmet } from 'react-helmet';

function Contact() {

  var contactTitle = "Guillaume Sauteur - Contact";

    window.onfocus = function () { document.title = contactTitle; } 
    return (
      <div className="Contact">
      <Helmet>
        <title>{contactTitle}</title>
        <meta name='description' content='Contact me to talk about your project : hello@guillaume-sauteur.ch.' />
      </Helmet>
        <div className="container-layout">
          <div className="container-inner">
            <ContactHero></ContactHero>
          </div>
        </div>
        <div className="socials-for-contact is-appear">
          <Socials></Socials>
        </div>
          <Credits></Credits>
      </div>
    );
  }
  
  export default Contact