import React from 'react'

const Services = () => {
  return (
    <div className="services-component">
      <div className="service-item">
        <h3 className="service-item-title">
        <span className="text-red">➔</span> Web
        </h3>
        <ul>
          <li className="service-item-object">Frontend Development</li>
          <li className="service-item-object">Web Design</li>
          <li className="service-item-object">UI/UX</li>
          <li className="service-item-object">Responsive Design</li>
          <li className="service-item-object">Mobile App</li>
        </ul>
      </div>

      <div className="service-item">
        <h3 className="service-item-title">
         <span className="text-red">➔</span> Branding
        </h3>
        <ul>
          <li className="service-item-object">Logotype</li>
          <li className="service-item-object">Visual Identity</li>
          <li className="service-item-object">Artistic Direction</li>
          <li className="service-item-object">Moodboard</li>
        </ul>
      </div>

      <div className="service-item">
        <h3 className="service-item-title">
        <span className="text-red">➔</span> Video
        </h3>
        <ul>
          <li className="service-item-object">2D/3D Motion Design Video</li>
          <li className="service-item-object">Intro/Opener</li>
          <li className="service-item-object">Video Montage</li>
        </ul>
      </div>

      <div className="service-item">
        <h3 className="service-item-title">
        <span className="text-red">➔</span> Social
        </h3>
        <ul>
          <li className="service-item-object">Social Network Communication</li>
          <li className="service-item-object">Content Creation for Social Network</li>
          <li className="service-item-object">Graphic Design Deployment</li>
          <li className="service-item-object">Adaptive Graphic Design</li>
        </ul>
      </div>
        {/* <ul>
            <li className="services-item">Web Development</li>
            <li className="services-item">Graphic Design</li>
            <li className="services-item">Video montage</li>
            <li className="services-item">User Interface design</li>
            <li className="services-item">Illustration</li>
            <li className="services-item">Sound Design</li>
            <li className="services-item">2D & 3D Motion Design</li>
        </ul> */}
    </div>
  )
}

export default Services