import React from 'react';
import { Link } from 'react-router-dom';
import './HomeHero.scss';
/**
 * Header Component should hold the menu trigger button.
 * onClick of this button we need to show or hide the sidebar navigation
 * The Menu Toggle state is maintained in the App Component.
 */
const Header = props => {
  const { menuState, setMenuState } = props;

  return (
    <header className="header">
      <div className="brand-icon">
        <Link className='menu-link is-moused' to="/">
          <span>Guillaume.</span>
        </Link>
      </div>
      <div>
        <button
          className={`is-moused burger-trigger ${menuState ? 'burger-close' : ''}`}
          onClick={() => setMenuState(!menuState)}
          aria-labelledby="burgerMenu"
        >
          <h3 className="burger-menu-indicator">MENU</h3>
          <div className='bars'></div>
          <div className='bars'></div>
          <div className='bars'></div>
        </button>
      </div>
    </header>
  );
};
export default Header;
