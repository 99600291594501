import React from 'react'

function SectorItem({name, id, date, img, link, alt, icon, description}) {
  return (
    <div className="sector-item is-moused is-appear">
        <a className="sector-link" rel="noreferrer" target="_blank" href={link}></a>
      <div className="sector-item-content">
        {/* <img src="" alt={name} /> */}
        <div className="sector-item-hero">
          <img src={`img/${img}.webp`} alt={alt} />
          <div className="section-item-icon">
            {icon}
          </div>
        </div>
        <div className="section-item-description">
          <h2 className="sector-item-title">
              {name}
          </h2>
          <p className="section-item-text">
              {description}
          </p>
          <h5 className="sector-item-date">
              {date}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default SectorItem