import React from 'react';
import DataSocials from './DataSocials';
import './Socials.scss';

function Socials() {
  return (
    <div className="socials">
        {DataSocials.map((DataSocials, index, icon) => {

            return (
            <a key={index} href={DataSocials.adress} target="_blank" rel="noreferrer">
                <div key={index} className="socials-item is-moused">
                    <div  key={icon} className="socials-icon">
                        <i className={`${ DataSocials.logo} `}></i>
                    </div>
                </div>
            </a>
            )
        })}

    </div>
  )
}

export default Socials