import React from 'react'
import { useNavigate } from 'react-router-dom';


function ProjectItem({ image, name, id, category, status, abb, nameResp }) {
    const navigate = useNavigate();

  return (
    <div className={`project-card card-hover ${name}`} onClick={() => {
        navigate("/Project/" + id)
      }}>
      <div className='img-container'>
        <picture>          
          <img src={`./img/${image}-little.webp`} alt={`Project ${name} made by Guillaume Sauteur.`} 
          // srcSet={`
          // ./img/Home-[375w].webp 375w,
          // ./img/${nameResp}-[750w].webp 750w,
          // `}
          />
        </picture>
      </div>
      <div className="project-card-info">
        <div className="project-card-info-inner">
        <h2 className="project-card-title">
          {name}
        </h2>
        <h3 className="project-card-category">
            <span className="crochet text-red">[</span> {category} <span className="crochet text-red">]</span> 
        </h3>
        <h4 className="project-card-status text-red">
            {status}
        </h4>
        </div>

      </div>
      </div>
  )
}

export default ProjectItem