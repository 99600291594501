import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function PageNotFound() {

  return (
    <>
    <Helmet>
      <title>404 error</title>
    </Helmet>
    <div className="page-not-found-container">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <img className="notFoundGif" src="./img/giphy.gif" alt="Homer Simpson thinks of a cymbal monkey." />
      <Link className="link" to="/"><b><span className="text-red">➔ Return to Homepage</span></b></Link>
    </div>
    </>
  )
}

export default PageNotFound