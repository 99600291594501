import HomeHero from './Header/HomeHero';
import Projects from './Projects/Projects';
import Section from './Extras/Section';
import Socials from './Extras/Socials';
import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Credits from './Extras/Credits';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Home() {

  var homeTitle = "Guillaume Sauteur";

  window.onfocus = function () { document.title = homeTitle; }; 

  useEffect(() => {
    document.querySelectorAll('.card-hover').forEach(link => {
      link.addEventListener('mouseover', function(){
        document.querySelector('.cursor-content').classList.add('is-circled');
      })
      link.addEventListener('mouseout', function(){
        document.querySelector('.cursor-content').classList.remove('is-circled');
      })
      link.addEventListener('click', function(){
        document.querySelector('.cursor-content').classList.remove('is-circled');
      })
    })
  }, []);

  useEffect(() => {
    var appear = document.querySelectorAll('.project-card');
    var appearElement = document.querySelectorAll('.other-reveal');
    let i;

    function reveal(){
        
        for( i = 0; i < appear.length; i++ ){
          
          var windowHeight = window.innerHeight;
          var topPoint = appear[i].getBoundingClientRect().top;
          var revealpoint = 50;
          
          if(topPoint < windowHeight - revealpoint){
          appear[i].classList.add('active');
          }
          else{
            appear[i].classList.remove('active');
          }
        }
      }

      function revealElement(){
        
        for( i = 0; i < appearElement.length; i++ ){
          
          var windowHeight = window.innerHeight;
          var topPoint = appearElement[i].getBoundingClientRect().top;
          var revealpoint = 180;
          
          if(topPoint < windowHeight - revealpoint){
          appearElement[i].classList.add('active');
          // tlCard.to('.project-card', { opacity: 1, y: 0, duration: .6, stagger: .4 })
          }
          else{
            appearElement[i].classList.remove('active');
            // tlCard.to('.project-card', { opacity: 0, y: 50, duration: .6 });
          }
        }
      }
        
window.addEventListener('scroll', reveal);
window.addEventListener('scroll', revealElement);
}, []);

    // useEffect(() => {

    //   gsap.registerPlugin(ScrollTrigger);

    //   let tlSection = gsap.timeline( { scrollTrigger: { trigger: ".wrapper", start: "-800px", end: "900", scrub: true, markers: false, endTrigger: ".Home" } } );

    //   tlSection.fromTo('.section-divider', { x: '-500px' }, { x: '500px' }, '-=1');

    // }, []);

    useEffect(() => {

      gsap.registerPlugin(ScrollTrigger);

      let tlSectionSocials = gsap.timeline( { scrollTrigger: { trigger: ".socials-title", start: "-400px", end: "1000", markers: false, once: false, endTrigger: ".socials-title", toggleActions: "play none none reverse" }} );

      tlSectionSocials.fromTo('.letter-animate', { y: '100%', rotate: "10deg" }, { y: 0, rotate: "0deg", stagger: 0.05 });

    }, []);

    // useEffect(() => {
    //   gsap.registerPlugin(ScrollTrigger);

    //   let tlLetters = gsap.timeline({ ScrollTrigger: { trigger: '.socials-container', start: '-50px', markers: true, once: true, endTrigger: "body" }, defaults:{ ease: "power4Out", duration: .5, stagger: {amount: .15} }  });

    //   tlLetters.fromTo('.letter-animate', { y: '100%', rotate: "10deg" }, { y: 0, rotate: "0deg" }, '-=1');
    // })

    return (
      <div className="Home">
      <Helmet>
        <title>{homeTitle}</title>
      </Helmet>
        <div className="container-layout">
          <div className="container-inner">
          <HomeHero></HomeHero>
          </div>
        </div>

          <Section></Section>

          <Projects></Projects>

          {/* <div className="sector-home">
            <div className="sector-home-container">
              <Link to='/sector' className="sector-home-link is-moused">
                Experimentations ➔
              </Link>
            </div>
          </div> */}

        <div className="socials-container">
          <h2 className="socials-title">
            <span className="letter-animate">S</span>
            <span className="letter-animate">O</span>
            <span className="letter-animate">C</span>
            <span className="letter-animate">I</span>
            <span className="letter-animate">A</span>
            <span className="letter-animate">L</span>
            <span className="letter-animate">S</span>
          </h2>
          <Socials></Socials>
          <div className="contact-more is-moused">
           <Link to="/contact">CONTACT ME<span className="disappear-appear">!</span></Link>
          </div>
        </div>
        <Credits></Credits>
      </div>
    );
  }

  export default Home