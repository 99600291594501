const DataProjects =  [
    {
        id: 0,
        name: 'DigiHome',
        image: 'DigiHome',
        category: 'UI Design',
        description: 'For a school project, we had 12 hours to design a home control application. The challenge was to create a very intuitive and consistent UI.',
        status: 'fictive',
        client: 'fictive',
        year: '2021',
        role: 'UI Design, Prototyping',
        credits: 'Personal project',
        restriction: '',
        website: '',
        video: '',
        previous: '1',
        abb: 'DigiHome',
        nameResponsive: '',
    },
    {
        id: 1,
        name: 'SportShoes',
        image: 'SportShoes',
        category: 'Graphic Design — Illustration',
        description: 'This project took place in a graphic design course. I had to choose a shoes brand that related to sport. I choose to work with VANS. The character design of Bart Simpson inspired me for this skull character.',
        status: 'fictive',
        client: 'VANS (fictive)',
        year: '2021',
        role: 'Graphic Design, Illustration',
        credits: 'Personal project',
        restriction: 'restriction',
        website: '',
        video: '',
        previous: '2',
        next: '0',
        nameResponsive: '',
    },
    {
        id: 2,
        name: 'Harbor',
        image: 'Harbor',
        category: '3D',
        description: 'This is the first 3D project we worked on. The idea was to develop a "farm-like" building intended to be implemented into an isometric game. I worked on a little harbor and I wanted to develop and describe the game, about how it works.',
        status: 'fictive',
        client: 'Mobile game industrie (fictive)',
        year: '2021',
        role: 'Sketching, 3D modeling',
        credits: 'Personal project',
        restriction: '',
        website: '',
        video: '',
        previous: '3',
        next: '1',
        nameResponsive: '',
    },
    {
        id: 3,
        name: 'Blacksad',
        image: 'Blacksad',
        category: 'UI, Storytelling, Frontend',
        webLanguage: 'HTML, CSS, JS',
        description: 'We had to make a unique website design from the famous comic book Blacksad. We decided to make an investigation while scrolling, with some interaction with the comic strips. On the website, we added a shop section in order to allow the customers to buy the book.',
        status: 'fictive',
        client: 'fictive',
        year: '2021',
        role: 'UI design, Web development, Motion design',
        credits: 'Benjamin Sauteur & Me',
        restriction: 'with-website',
        website: 'https://dafuziah.github.io/Blacksad/',
        video: '',
        previous: '4',
        next: '2',
        nameResponsive: '',
    },
    {
        id: 4,
        name: 'FS agencebois',
        image: 'fs-agencebois',
        category: 'Webdesign, redesign, Frontend',
        webLanguage: 'PHP, CSS, JS',
        description: 'I was commissioned to redesign the whole website of the FS agencebois company, specializing in carpentry and fittings. The challenge here was to refresh the old webdesign they had by highlighting their projects with big images. I choose to work with neutral colors, and adding a bit of yellow in it, like in their logo.',
        status: 'real project',
        client: 'FS Agencebois',
        year: '2022',
        role: 'Web design, Web development',
        credits: '© fsagencebois',
        restriction: 'external-website',
        website: 'https://www.fsagencebois.ch/',
        video: '',
        previous: '5',
        next: '3',
        nameResponsive: 'Fs-agencebois',
    },
    {
        id: 5,
        name: 'Home made by Sophie',
        image: 'HMBS',
        category: 'Webdesign & Frontend, UI Design, Video',
        webLanguage: 'HTML, CSS, JS',
        description: 'This project took place as the final project of my second year in eikon.',
        status: 'fictive',
        client: 'Home made by Sophie (fictive)',
        year: '2021',
        role: 'Sketching, 3D modeling',
        credits: 'Personal project',
        restriction: 'has-external-link',
        website: 'https://dafuziah.github.io/HMBS-Landing-page/',
        video: '',
        previous: '6',
        next: '4',
        nameResponsive: '',
    },
    {
        id: 6,
        name: 'Circles',
        image: 'Circles',
        category: 'Motion design',
        description: 'This project was the first in motion design. The main goal was to learn After Effect by using easy transform, transition with simple circle shape. The Sound was included in the project, and the animation had to match with the music. At the end, the whole project is builded by the animations made by the whole class, with the same intro & outro to make it loop.',
        status: 'fictive',
        client: 'Animation school',
        year: '2021',
        role: 'Motion Design',
        credits: 'Personal project',
        restriction: 'only-video',
        website: '',
        video: '../video/ilovecircles.mp4',
        previous: '7',
        next: '5',
        nameResponsive: '',
    },
    {
        id: 7,
        name: 'Airjordan',
        image: 'Airjordan',
        category: 'UI Design, Frontend, PHP, SQL',
        webLanguage: 'PHP, CSS, JS',
        description: 'Through the history and evolution of the famous Airjordan shoes, we had to develop a website that lists all Airjordan shoes, annotating its designer, a small description and adding a possibility to buy it.',
        status: 'fictive',
        client: 'Nike (fictive)',
        year: '2021',
        role: 'UI, Frontend',
        credits: 'Guillaume Sauteur',
        restriction: 'has-external-link',
        website: 'https://main--creative-cassata-ba7151.netlify.app/',
        next: '6',
        previous: '8',
        nameResponsive: 'Airjordan',
    },
    {
        id: 8,
        name: 'COSMOS',
        image: 'COSMOS',
        category: 'Motion design, 3D',
        description: 'Opener for the space documentary "COSMOS".',
        status: 'fictive',
        client: 'COSMOS (victive)',
        year: '2022',
        role: 'Motion Design, 3D',
        credits: 'Personal project, shuttle model by MrSobias',
        restriction: 'only-video',
        website: '',
        video: '../video/opener.mp4',
        next: '7',
        previous: '9',
        nameResponsive: '',
    },
    {
        id: 9,
        name: '3D Abstract Showreel',
        image: '3D Abstract Showreel',
        category: 'Motion design, 3D',
        description: 'This is a 3D Motion Project with an abstract intention. This project was carried out in 2 parts : by the "Bisso Na Bisso" Method, each student had to create at least 3 short 3D Abstract Motion Sequence. Then the objective was to create a 20s to 1min video using several sequences created by the students, based on rhythmic music.',
        status: 'fictive',
        client: 'fictive',
        year: '2022',
        role: 'Motion Design, 3D',
        credits: 'eikon students, Exys - Momokusu Iwata',
        restriction: 'only-video',
        website: '',
        video: '../video/3Dshowreel.mp4',
        next: '8',
        previous: '10',
        nameResponsive: '3D-Abstract-Showreel',
    },
    {
        id: 10,
        name: 'Fondation Estérelle-Arcadie',
        image: 'fondation-esterelle',
        category: 'UI Design, Frontend',
        description: "I was tasked with redesigning the Estérelle-Arcadie foundation's website, a residential facility for people with alcohol-related addiction problems, based in Vevey. My aim was to make the website simple by using a sober and welcoming design. I also made a short teaser video for the foundation.",
        status: 'Real Project',
        client: 'Fondation Estérelle-Arcadie',
        year: '2023',
        role: 'UI Design, Frontend',
        credits: 'Fondation Estérelle-Arcadie',
        restriction: 'external-website',
        website: 'https://www.alcool.ch/',
        video: '',
        next: '9',
        nameResponsive: '3D-Abstract-Showreel',
    },
];

export {DataProjects}